@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.jost{
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
}


body{
  font-family: "Jost", sans-serif;
  background-color: #050911;
  background-image: url(./assets/glow.png);
  background-size: cover  ;
}





.particle {
  position: fixed;
  width: 1px;
  height: 1px;
  background-color: #02D5FF;
  border-radius: 50%;
  animation: move 10s linear infinite;
  z-index: -2;
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100vw, 100vh);
  }
}


.spin {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* src/Marquee.css */
.marquee-container {
  width: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  box-sizing: border-box;
}

.marquee-content {
  display: flex;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

.marquee-content > * {
  flex: 0 0 auto; /* Ensure each child is not flexible and maintains its size */
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
